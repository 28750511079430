import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// services
import { trackProductViewed } from '../../services/tracking/tracking';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Reviews from '@components/reviews/reviews';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
// styles
import HeroHealthProductWithCarousel from '../../components/hero-health-product-with-carousel';
import HowItWorksWithAccordions from '../../components/how-it-works-with-accordions/how-it-works-with-accordions';
import VitaminCardsCarousel from '../../components/vitamin-cards-carousel';
import WhyWeAreDifferent from '../../components/why-we-are-different';
import NutritionalInformation from '../../components/nutritional-information/nutritional-information';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import PartnersSlider from '@src/components/partners-slider/partners-slider';

interface IProps {
  isLandingPage?: boolean;
  isFreeTrial?: boolean;
}

const KidsMultivitaminPage: React.FC<IProps> = ({ isLandingPage = false }) => {
  const content = useStaticQuery(graphql`
    query KidsMultivitamin {
      directusKidsMultivitamin {
        page_title
        page_description
        hero_title
        hero_background_color
        hero_description_1
        hero_description_2
        hero_description_3
        expertly_designed_title
        expertly_designed_subtext
        hero_image_1 {
          filename_disk
        }
        hero_image_2 {
          filename_disk
        }
        hero_image_3 {
          filename_disk
        }
        hero_image_4 {
          filename_disk
        }
        reviews_title
        reviews {
          reviewerName
          review
        }
        expertly_designed_title
        expertly_designed_description
        expertly_designed_subtext
        expertly_designed_items {
          title
          description
          filename_disk
        }
        additional_benefits_title
        additional_benefits_vitamins {
          filename_disk
          name
          measurement
          points {
            point
          }
        }
        why_we_are_different_title
        why_we_are_different_points {
          point
        }
        why_we_are_different_description
        why_we_are_different_image {
          filename_disk
        }
        why_we_are_different_tick_image {
          filename_disk
        }
        nutritional_information_title
        nutritional_information_headers {
          text
        }
        nutritional_information_rows {
          nutrient
          quantity
          nrv
        }
        nutritional_information_description
        faqs_section_title
        faqs_description
        faqs {
          question
          answer
        }
      }
    }
  `);

  const { directusKidsMultivitamin } = content;
  const { dataPlans: data, loading, isAvailable } = useGetProductPlansData('kids-multivitamins');

  // tracking
  useEffect(() => {
    trackProductViewed({
      name: 'Kids multivitamin',
      url: location.href,
      product_id: 'kids-multivitamins',
      category: 'kids multivitamin',
    });
  }, []);

  return (
    <Layout showFooter={true}>
      <SEO
        title={directusKidsMultivitamin.page_title}
        description={directusKidsMultivitamin.page_description}
      />

      <HeroHealthProductWithCarousel
        showFreeDeliveryText={true}
        title={directusKidsMultivitamin.hero_title}
        subtitle={directusKidsMultivitamin.hero_description_1}
        descriptions={[
          directusKidsMultivitamin.hero_description_2,
          directusKidsMultivitamin.hero_description_3,
        ]}
        images={[
          directusKidsMultivitamin.hero_image_1,
          directusKidsMultivitamin.hero_image_2,
          directusKidsMultivitamin.hero_image_3,
          directusKidsMultivitamin.hero_image_4,
        ]}
        backgroundColor={directusKidsMultivitamin.hero_background_color}
        loadingPlans={loading}
        plans={data ? data.product_plans : []}
        lazyload={false}
        showDaysOffer={false}
        isAvailable={isAvailable}
      />

      <PartnersSlider isWhite />

      <HowItWorksWithAccordions
        background="Cream"
        title={directusKidsMultivitamin.expertly_designed_title}
        items={directusKidsMultivitamin.expertly_designed_items}
        description={directusKidsMultivitamin.expertly_designed_description}
        footnote={directusKidsMultivitamin.expertly_designed_subtext}
      />

      <VitaminCardsCarousel
        background="Secondary"
        title={directusKidsMultivitamin.additional_benefits_title}
        vitamins={directusKidsMultivitamin.additional_benefits_vitamins}
      />

      <WhyWeAreDifferent
        image={directusKidsMultivitamin.why_we_are_different_image}
        title={directusKidsMultivitamin.why_we_are_different_title}
        description={directusKidsMultivitamin.why_we_are_different_description}
        items={directusKidsMultivitamin.why_we_are_different_points}
        tick_image={directusKidsMultivitamin.why_we_are_different_tick_image}
      />

      <Reviews
        title={directusKidsMultivitamin.reviews_title}
        items={directusKidsMultivitamin.reviews}
        showReview
        hideTrustPilot
      />

      <NutritionalInformation
        background="PrimaryLighter"
        title={directusKidsMultivitamin.nutritional_information_title}
        rows={directusKidsMultivitamin.nutritional_information_rows}
        headers={directusKidsMultivitamin.nutritional_information_headers}
        description={directusKidsMultivitamin.nutritional_information_description}
      />

      <StillHaveQuestion
        accordion={directusKidsMultivitamin.faqs}
        description={directusKidsMultivitamin.faqs_description}
      />
    </Layout>
  );
};

export default KidsMultivitaminPage;
